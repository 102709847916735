// App.tsx
import React from 'react';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import SaveMessage from "./components/SaveMessage";
import UsersTable from "./components/UsersTable";

const App = () => {
  return (
      <div className="App">
          Test
        <SignUp />
        <SignIn />
          <SaveMessage/>
          <UsersTable/>
      </div>
  );
}

export default App;
