// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDI7yyYCI0ZFVpwa2F-iqMSTqTZyn0paWQ",
    authDomain: "authtest-df4b5.firebaseapp.com",
    projectId: "authtest-df4b5",
    storageBucket: "authtest-df4b5.appspot.com",
    messagingSenderId: "257195480531",
    appId: "1:257195480531:web:8db5afb365f4fea76ccb26",
    measurementId: "G-MD8C1XNVRX"
};

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export const googleProvider = new firebase.auth.GoogleAuthProvider();
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

