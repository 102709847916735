import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import {auth, firestore} from "../firebaseConfig";
import User from "../model/User"
import {addDoc, collection} from "firebase/firestore";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const userRef = collection(firestore, "users");

    const signUp = async (e) => {
        e.preventDefault();
        try{
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const signUp = userCredential.user;
            const newUser = new User(signUp.uid, signUp.email, "USER", new Date(), new Date(), "ACTIVE");
            const res = await addDoc(userRef, newUser.toJSON());
            console.log(userCredential)
            console.log(newUser)
            console.log(res)

        } catch(error) {
            console.log(error);
        };
    };

    return (
        <div className="sign-in-container">
            <form onSubmit={signUp}>
                <h1>Create Account</h1>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></input>
                <input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                ></input>
                <button type="submit">Sign Up</button>
            </form>
        </div>
    );
};

export default SignUp;