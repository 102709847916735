import React, { useState, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs } from 'firebase/firestore';
import {firestore} from "../firebaseConfig";

function MessageInput() {
    const [message, setMessage] = useState('');
    const ref = collection(firestore, "messages");
    const [savedMessages, setSavedMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            const db = getFirestore();
            const messagesRef = collection(db, "messages");
            const messageDocs = await getDocs(messagesRef);
            const messages = messageDocs.docs.map(doc => doc.data().message);
            setSavedMessages(messages);
            console.log(messages)
        };

        fetchMessages();
    }, []);

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    }

    const handleSaveClick = () => {
        console.log('Message Saved:', message);

        let data = {
            message: message
        }
        try{
            addDoc(ref, data);
        } catch (e){
            console.log(e);
        }
    }

    return (
        <div>
            <h1>Database Test</h1>
            <label htmlFor="message-input">Enter Message</label>
            <input
                type="text"
                id="message-input"
                value={message}
                onChange={handleInputChange}
            />
            <button onClick={handleSaveClick}>Save</button>
            <h1>Saved Messages</h1>
            <div>I'm lazy so, refresh the page after each save kekekekee</div>
            {savedMessages.map((msg,idx)=><li key={idx}>{msg}</li>)}

        </div>
    );
}

export default MessageInput;
