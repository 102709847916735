import React, { useState, useEffect } from 'react';
import { collection, getFirestore, getDocs } from 'firebase/firestore';

function UsersTable() {
    const [saveUsers, setSavedUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const db = getFirestore();
            const usersRef = collection(db, "users");
            const usersDocs = await getDocs(usersRef);
            const users = usersDocs.docs.map(doc => {
                return doc.data()
            });
            setSavedUsers(users);
        };

        fetchUsers();
    }, []);


    return (
        <div>
            <h1>Saved Users</h1>
            {saveUsers.map((user,idx)=><li key={idx}>{user.email}, {user.joinedDate}, {user.id}</li>)}

        </div>
    );
}

export default UsersTable;
