export default class User {
    /**
     * Creates a new User instance.
     * @param {string} id - Unique identifier for the user.
     * @param {string} email - User's email address.
     * @param {string} [username] - Optional username for the user.
     * @param {string} role - The role assigned to the user (e.g., USER, ADMIN).
     * @param {Date} joinedDate - Date when the user registered.
     * @param {Date} lastLoginDate - Last date the user logged in.
     * @param {string} status - Current status of the user (e.g., ACTIVE, INACTIVE).
     * @param {string} [fullName] - Optional full name of the user.
     * @param {string} [profilePicture] - Optional URL/path to user's profile picture.
     */
    constructor(id, email, role, joinedDate, lastLoginDate, status, username, fullName, profilePicture) {
        this.id = id;
        this.email = email;
        this.role = role;
        this.joinedDate = joinedDate;
        this.lastLoginDate = lastLoginDate;
        this.status = status;

        // Optional properties
        this.username = username || null;        // Default to null if not provided
        this.fullName = fullName || null;        // Default to null if not provided
        this.profilePicture = profilePicture || null;  // Default to null if not provided
    }

    /**
     * Convert the user object to a JSON representation.
     * @returns {object} JSON representation of the user.
     */
    toJSON() {
        return {
            id: this.id,
            email: this.email,
            role: this.role,
            joinedDate: this.joinedDate.toISOString(),
            lastLoginDate: this.lastLoginDate.toISOString(),
            status: this.status,
            username: this.username,
            fullName: this.fullName,
            profilePicture: this.profilePicture
        };
    }
}

// Example usage:
// const newUser = new User("123456", "example@email.com", "USER", new Date(), new Date(), "ACTIVE");
// console.log(newUser);
